import React from "react";
import { graphql, Link } from "gatsby";
import { DefaultLayout, SidebarLayout, SidebarBody } from "../layouts";
import { Hero } from "../components";
import styled from "@emotion/styled";

const PagingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  > a {
    margin: 0 5px;
  }
`;

const SidebarHeader = styled.h3`
  color: #693;
`;

const PageLink = styled((props) => <Link {...props} />)`
    font-weight: ${props => props.isactive === 'true' ? 'bold' : 'normal'};
`;
const Header = styled(props => <Link {...props} />)`
  color: #666;
  font-weight: bold;
  cursor: pointer;
  margin: 10px 0;
`;

const PaginationButtons = ({ pageContext }) => {
  const { currentPage, numPages } = pageContext;
  return (
    <PagingWrapper>
      Page: 
      {Array.from({ length: numPages }).map((_, i) => (
        <PageLink to={i === 0 ? `/news/` : `/news/${i + 1}`} key={i}
        isactive={((i+1) === currentPage).toString()}>
          {i + 1}
        </PageLink>
      ))}
    </PagingWrapper>
  );
};

const NewsPage = ({ pageContext, data }) => {
  const news = data.newsPages.edges;
  return (<DefaultLayout>
    <Hero
      image=""
      showBox={false}
    />
    <SidebarLayout
      body={
        <React.Fragment>
          <SidebarBody>
            <PaginationButtons pageContext={pageContext} />
            {news.map((n, index) => (
              <React.Fragment key={index}>
                <Header to={n.node.fields.slug}>{n.node.frontmatter.title}</Header>
                <div dangerouslySetInnerHTML={{
                  __html: n.node.excerpt
                }}></div>
              </React.Fragment>
            ))}
            <PaginationButtons pageContext={pageContext} />
          </SidebarBody>
        </React.Fragment>
      }
      sidebar={
        <React.Fragment>
          <SidebarHeader>Contacts</SidebarHeader>
          <h3>Shareholders</h3>
          <p>
            <span>Telephone: +44 1481 741250</span>
            <br />
            <span>
              <a href="mailto:eea@mjhudson.com">eea@mjhudson.com</a>
            </span>
          </p>
          <h3>Intermediaries</h3>
          <p>
            <span>EEA Fund Management Limited (Marketing Agent)</span>
            <br />
            <span>
              Your usual EEA contact or <a href="mailto:info@eeafm.com">info@eeafm.com</a>
            </span>
          </p>
          <h3>Press enquiries</h3>
          <p>
            <span>Stuart Thomson</span>
            <br />
            <a href="tel:+44 7717 802935">
              <span>+44 7717 802935</span>
            </a>
          </p>
        </React.Fragment>
      }
    />
  </DefaultLayout>
  );
}

export const query = graphql`
query newsArticleQuery($skip: Int!, $limit: Int!) {
    newsPages: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(news-pages)/" } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
    edges {
      node {
        frontmatter {
          title
        }
        fields {
          slug
        }
        excerpt(pruneLength: 600)
      }
    }
  }
}
`;


export default NewsPage;
